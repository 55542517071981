import React from 'react'
import ReactImageMagnify from 'react-image-magnify';

const MagnigyImage = ({ megnifyImage }) => {
    return (
        <div className='parent-megnify'>
            <div style={{ height: '90vh', position: 'absolute' }}>
                <div className='image-maginify'>
                    <ReactImageMagnify {...{
                        enlargedImageContainerStyle: {
                            border: '1px solid white'
                        },
                        smallImage: {
                            alt: 'Wristwatch by Ted Baker London',
                            isFluidWidth: true,
                            src: megnifyImage,
                        },
                        largeImage: {
                            src: megnifyImage,
                            width: 1500,
                            height: 1000
                        }
                    }} />
                </div>
            </div>
        </div>
    )
}

export default MagnigyImage;