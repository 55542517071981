import Carousel from 'react-bootstrap/Carousel';


const ImgCarousel = () => {

    return (
        <div className='imgcar'>
            <Carousel data-bs-theme="dark" previcon={<span aria-hidden="true" classname="carousel-control-prev-icon" />}>
                <Carousel.Item interval={1000}>
                    <img
                        className="d-block w-100"
                        src="https://catalog.wlimg.com/1/11310019/other-images/498809.jpg"
                        alt="First slide"
                    />
                    <Carousel.Caption>
                    <h5 className='caption-text'> 
                    Cumin Powder</h5>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item interval={1000}>
                    <img
                        className="d-block w-100"
                        src="https://catalog.wlimg.com/1/11310019/other-images/498808.jpg"
                        alt="Second slide"
                    />
                    <Carousel.Caption>
                    <h5 className='caption-text'> 
                        Turmeric Powder
                    </h5>

                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item interval={1000}>
                    <img
                        className="d-block w-100"
                        src="https://catalog.wlimg.com/1/11310019/other-images/498807.jpg"
                        alt="Third slide"
                    />
                    <Carousel.Caption>
                   <h5 className='caption-text'> Red Chilli Powder </h5>


                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>

        </div>
    )
}

export default ImgCarousel;