import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { Box } from '@mui/material';

export default function ContactInfo({ icon, userInfo, index, contactDetails }) {
    return (
        <List sx={{ width: '100%', borderBottom: index !== contactDetails.length - 1 ? '1px dotted #5f7f36' : "none" }}>
            <ListItem>
                <ListItemAvatar>
                    <Box sx={{
                        width: '45px', height: '45px', background: '#5F7F36', color: 'white', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'
                    }}>
                        {icon}
                    </Box>
                </ListItemAvatar>
                <ListItemText primary={userInfo?.header} secondary={userInfo?.description} />
            </ListItem>
        </List >
    );
}