import { Box, Button, Grid, InputBase, Typography, styled } from '@mui/material';
import React from 'react';
import './../Css/about.css';
import { MdChevronRight } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import AppFooter from './AppFooter/Footer';
import ContactInfo from './contactInfo';
import { FaUser } from "react-icons/fa";
import * as Yup from "yup";
import { Field, Form, Formik } from 'formik';
import Textarea from "@mui/joy/Textarea";
import { toast } from 'react-hot-toast';
import { send } from 'emailjs-com';


const ContactUsPage = () => {
    const BoxWrapper = styled(Box)(() => ({
        marginTop: '5.2rem',
        padding: "0px 20%",
        width: '100%',
        '.parent-navigate': {
            backgroundColor: '#f2f2f2',
            fontSize: '13px',
            padding: '12px'
        },
        '.about-header': {
            fontSize: '24px',
            fontWight: 800,
            padding: '10px'
        },
        '.inner-about-header': {
            fontSize: '18px',
            fontWight: 800,
            padding: '10px'
        }, '.parent-details': {
            border: '1px solid #ccc',
            backgroundColor: '#f1f1f1',
            padding: '0px 30px'
        },
        ".getFormTitle": {
            fontSize: "36px",
            fontWeight: "700",
            paddingBottom: "50px",
            paddingLeft: "100px",
            // color: "#111013",
            color: "#1E2124",
            lineHeight: '44px',
            display: "flex",
            alignItems: "center"
        },
        '.p-0': {
            padding: '0px !important'
        },

        ".formTitle": {
            fontSize: "14px",
            fontWeight: 500,
            paddingBottom: "8px",
            paddingTop: "14px",
            // color: "#161519",
            color: "#1E2124",
            lineHeight: "19px",
            fontFamily: "inherit",
        },
        ".inputLable": {
            color: "black",
            height: "40px",
            padding: "5px 14px",
            width: '100%',
            border: '1px solid #ccc'
        },
        ".selctBox": {
            color: "#60606B",
            height: "48px",
            borderRadius: "8px",
            marginTop: "4px",
        },
        ".btnBox": {
            display: "flex",
            justifyContent: "start",
        },
        ".getQuoteBtn": {
            background: '#5F7F36',
            color: "#FFFFFF",
            // border: "1px solid #D90A2C",
            fontWeight: "700",
            padding: "12px 18px",
            lineHeight: "24px",
            marginTop: '15px'
        },
        ".getQuoteBtn:hover": {
            backgroundColor: "#D90A2C",
        },
        ".form-main-box": {
            width: "100%",
            paddingBottom: '80px',
            // padding: "196px 0px",
        },
        ".error-msg": {
            color: "red",
            fontSize: "12px",
        },
        ".lableDropDown": {
            color: "#60606B",
        },
        " .formControl": {
            minWidth: "100%",
        },
        ".confirm-check-box": {
            margin: '10px 0px'
        },
    }))
    const navigate = useNavigate();
    const contactDetails = [
        { header: 'Contact Person', description: "Mr. Meet Patel", id: 1 },
        { header: 'Address', description: 'Flat No. 102 1st Floor Arham Flats 40 Ft Road Mavdi, Rajkot, Gujarat, India - 360004', id: 2 },
        { header: 'Call Us', description: '+91-9510582701, +91-7600781161', id: 3 },
        { header: 'Email', description: 'denish@agribucketinternational.com', id: 4 },
        { header: 'Alt. Email', description: 'official.agribucketexim@gmail.com', id: 5 },
        { header: 'Web Address', description: 'https://www.agribucketinternational.co.in', id: 6 },
        { header: 'Web Page', description: 'https://www.exportersindia.com/agri-bucket-international/', id: 7 }
    ]
    const SignupSchema = Yup.object().shape({
        name: Yup.string().required("Name is Required"),
        email: Yup.string().required("EmailId is Required").email("Invalid Email"),
        city: Yup.string().required("City is Required"),
        detail: Yup.string().required("Detail is Required"),
        mobile: Yup.string().required("Mobile is Required"),
        detail: Yup.string().required("Detail is Required"),
        country: Yup.string().required("Country is Required"),
        state: Yup.string().required("State is Required"),
    });

    const onSubmit = (data) => {
        send("service_gwbvwjo", "template_pbgwkjo", data, "C3Mg2tnmRpb3HzdMe")
            .then((response) => {
                console.log("Success", response.status, response.text);
                toast.success('Email sent successfully !!')
            })
            .catch((err) => {
                console.log("Failed", err);
            });
    };

    const countriesData = [
        { name: 'Afghanistan', id: 1 },
        { name: 'Albania', id: 2 },
        { name: 'Switzerland', id: 3 },
        { name: 'Algeria', id: 4 },
        { name: 'Taiwan', id: 5 },
        { name: 'Tonga', id: 6 },
        { name: 'Turkey', id: 7 },
        { name: 'Turkmenistan', id: 8 },
        { name: 'United Kingdom', id: 9 },
        { name: 'United States', id: 10 },
        { name: 'Uzbekistan', id: 11 },
        { name: 'Vatican City', id: 12 },
        { name: 'Venezuela', id: 13 },
        { name: 'Vietnam', id: 14 },
    ];
    const stateData = [
        { state: 'Andhra Pradesh', id: 1 },
        { state: 'Assam', id: 2 },
        { state: 'Switzerland', id: 3 },
        { state: 'Chhattisgarh', id: 4 },
        { state: 'Goa', id: 5 },
        { state: 'Gujarat', id: 6 },
        { state: 'Turkey', id: 7 },
        { state: 'Haryana', id: 8 },
        { state: 'Jharkhand', id: 9 },
        { state: 'Karnataka', id: 10 },
        { state: 'Kerala', id: 11 },
        { state: 'Madhya Pradesh', id: 12 },
        { state: 'Manipur', id: 13 },
        { state: 'Meghalaya', id: 14 },
    ];

    return (
        <React.Fragment>
            <BoxWrapper>
                <Typography className='about-header'>
                    AGRI BUCKET INTERNATIONAL
                </Typography>
                <Box className="parent-navigate">
                    <span onClick={() => navigate("/")} style={{ color: 'gray', cursor: 'ponter' }}>Home</span> <MdChevronRight /> Contact Us
                </Box>
                <Grid container spacing={2} sx={{ mt: 3, mb: 5 }}>
                    <Grid item xs={12} md={6}>
                        <Box className="parent-details">
                            <Typography className='inner-about-header'>
                                AGRI BUCKET INTERNATIONAL
                            </Typography>
                            {
                                contactDetails?.map((data, index) => {
                                    return (
                                        <ContactInfo icon={<FaUser />} userInfo={data} index={index} contactDetails={contactDetails} />
                                    )
                                })
                            }
                        </Box>

                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={{ p: 2 }}>
                            <Formik
                                initialValues={{
                                    name: "",
                                    email: "",
                                    city: "",
                                    mobile: "",
                                    detail: "",
                                    country: "",
                                    state: ""
                                }}
                                enableReinitialize={true}
                                validationSchema={SignupSchema}
                                onSubmit={onSubmit}
                            >
                                {({ errors, touched, handleSubmit, values, handleChange }) => (
                                    <Form onSubmit={handleSubmit}>
                                        <Box className="form-main-box common-top-padding">

                                            <Typography className="formTitle">
                                                Your Name *
                                            </Typography>
                                            <InputBase
                                                name="name"
                                                className="inputLable"
                                                placeholder=" Enter Your Name"
                                                fullWidth
                                                value={values.name}
                                                onChange={handleChange}
                                            />
                                            {touched.name && errors.name && (
                                                <Typography className="error-msg">
                                                    {errors.name}
                                                </Typography>
                                            )}
                                            <Typography className="formTitle">Email *</Typography>
                                            <InputBase
                                                className="inputLable"
                                                name="email"
                                                value={values.email}
                                                placeholder="Enter Your Email ID"
                                                fullWidth
                                                onChange={handleChange}
                                            />
                                            {touched.email && errors.email && (
                                                <Typography className="error-msg">
                                                    {errors.email}
                                                </Typography>
                                            )}
                                            <Typography className="formTitle">City *</Typography>
                                            <InputBase
                                                className="inputLable"
                                                name="city"
                                                value={values.city}
                                                placeholder="Enter Your city"
                                                fullWidth
                                                onChange={handleChange}
                                            />
                                            {touched.city && errors.city && (
                                                <Typography className="error-msg">
                                                    {errors.city}
                                                </Typography>
                                            )}
                                            <Typography className="formTitle">Mobile *</Typography>
                                            <InputBase
                                                className="inputLable"
                                                name="mobile"
                                                value={values.mobile}
                                                placeholder="Enter Your Mobile"
                                                fullWidth
                                                onChange={handleChange}
                                            />
                                            {touched.mobile && errors.mobile && (
                                                <Typography className="error-msg">
                                                    {errors.mobile}
                                                </Typography>
                                            )}
                                            <Typography className="formTitle">Select Country*</Typography>
                                            <Field className="inputLable" as="select" name="country">
                                                <option value="">Select country</option>
                                                {countriesData?.map((data, index) => {
                                                    return (
                                                        <option value={data?.name}>{data?.name}</option>
                                                    )
                                                })}
                                            </Field>
                                            {touched.country && errors.country && (
                                                <Typography className="error-msg">
                                                    {errors.country}
                                                </Typography>
                                            )}
                                            <Typography className="formTitle">Select State*</Typography>
                                            <Field className="inputLable" as="select" name="state">
                                                <option value="">Select state</option>
                                                {stateData?.map((data, index) => {
                                                    return (
                                                        <option value={data?.state}>{data?.state}</option>
                                                    )
                                                })}
                                            </Field>
                                            {touched.state && errors.state && (
                                                <Typography className="error-msg">
                                                    {errors.state}
                                                </Typography>
                                            )}

                                            <Typography className="formTitle">Enquiry Details </Typography>
                                            <Textarea
                                                // className="inputLable"
                                                placeholder="Write Enquiry Details "
                                                minRows={3}
                                                name='detail'
                                                value={values.detail}
                                                onChange={handleChange}
                                            />
                                            {touched.detail && errors.detail && (
                                                <Typography className="error-msg">
                                                    {errors.detail}
                                                </Typography>
                                            )}

                                            <Box className="btnBox">
                                                <Button
                                                    className="getQuoteBtn bg-purple-gradient"
                                                    variant="raised"
                                                    type="submit"
                                                >
                                                    Submit
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Form>
                                )}
                            </Formik>
                        </Box>
                    </Grid>
                </Grid>
            </BoxWrapper>
            <AppFooter />
        </React.Fragment >
    )
}

export default ContactUsPage;
