import { Button, Card, Col, Container, Image, Row, Stack } from "react-bootstrap";
import { IoIosCall } from 'react-icons/io';
import { PiReadCvLogoBold } from 'react-icons/pi';
import { useParams } from "react-router-dom";
import chilli from '../../assets/images/Products/chilli.png'
import turmric from '../../assets/images/Products/turmeric.png'
import cumin from '../../assets/images/Products/cumin.png'
import MagnigyImage from "./ImgSmall";
import Explore from "./Explore";


const ProductDetails = () => {


    const ProductData = [
        {
            id: 0,
            image: chilli,
            title: "Red Chilli Powder",
            businessType: "Supplier, Trader",
            // cultivationType: "Organic",
            color: "Red",
            form: "Powder",
            certification: "FSSAI Certified",
            packingType: "Plastic Packet",
            location: "Anywhere in India"
        },
        {
            id: 1,
            image: turmric,
            title: "Turmeric Powder",
            businessType: "Supplier, Trader",
            // cultivationType: "Organic",
            color: "Yellow",
            form: "Powder",
            certification: "FSSAI Certified",
            packingType: "Plastic Packet",
            location: "Anywhere in India"
        },
        {
            id: 2,
            image: cumin,
            title: "Cumin Powder",
            businessType: "Supplier, Trader",
            // cultivationType: "Organic",
            color: "Brown",
            form: "Powder",
            certification: "FSSAI Certified",
            packingType: "Plastic Packet",
            location: "Anywhere in India"
        },
    ]
    const params = useParams()

    const productId = params?.productId;
    const item = ProductData?.find(obj => {
        return obj?.id === parseInt(productId)
    })

    const exploreProductData = ProductData?.filter((data) => data?.id != productId)

    return (
        <div className="product-details">

            <div className="product-details-title">
                {item?.title}
            </div>

            <Container className="product-details-card" >
                <>
                    <Row  >
                        {
                            <>
                                <Col xl={4} lg={4} md={12} sm={12} xs={12} className="parent-one" >
                                    <div>
                                        <MagnigyImage megnifyImage={item?.image} />
                                    </div>
                                </Col>
                                <Col xl={8} lg={8} md={12} sm={12} xs={12} className="product-description" >
                                    <div className="product-details-info">
                                        <div className="product-details-title1">{item?.title}</div>
                                        <Button className="product-details-price-btn">Get Best Price</Button>
                                        <div className="product-details-kg"> MOQ : 50 Kilogram</div>

                                        <div >

                                            <table className="product-details-info-1" >
                                                <tr className="product-info-tr" >
                                                    <td>Business Type</td>
                                                    <td>{item.businessType}</td>

                                                </tr>
                                                {/* <tr className="product-info-tr">
                                                    <td>Cultivation Type</td>
                                                    <td>{item?.cultivationType}</td>

                                                </tr> */}
                                                <tr className="product-info-tr">
                                                    <td>Color</td>
                                                    <td>{item?.color}</td>

                                                </tr>
                                                <tr className="product-info-tr">
                                                    <td>Form</td>
                                                    <td>{item?.form}</td>

                                                </tr>
                                            </table>

                                        </div>
                                        <div className="product-location">
                                            <h4 className="location-data">Preferred Buyer From</h4>
                                            <table className="product-details-info-1">
                                                <tr className="product-info-tr">
                                                    <td>Location</td>
                                                    <td>{item?.location}</td>

                                                </tr>
                                            </table>
                                        </div>
                                        <br></br>
                                        <div className="product-details-data-btn">
                                            <div className="product-details-data-btn-call">
                                                <Button className="call-btn" > Request to Call </Button></div>
                                            <br></br>
                                            <div className="product-data-btn-send">
                                                <Button className="send-btn">  Send Enquiry </Button> </div>
                                        </div>
                                    </div>
                                </Col>
                            </>

                        }


                    </Row>
                </>

            </Container>

            {/* <Container>
                <div className="product-details1-main">
                    <div className="product-details1">
                        <div className="product-details1-title">
                            <p className="details1-title"></p>
                        </div>
                    </div>
                </div>

            </Container> */}
            {/* //! explore product */}
            <Container className="product-details-card" >
                <Row>
                    <div className="explore-title">
                        Explore More Products
                    </div>
                    <div className="main-parent-explore">
                        {
                            exploreProductData?.map((data, index) => {
                                return (
                                    <div key={index}>
                                        <Explore data={data} />
                                    </div>
                                )
                            })
                        }
                    </div>
                </Row>
            </Container>



        </div>
    )
}

export default ProductDetails;