import { Card, Col, Container, Row } from "react-bootstrap";

const OfferTeam = ({ data }) => {
    return (
        <>
            <div className="offerTeam-main">
                <Container fluid >
                    <Row  >
                        <Col xs={12} sm={12} md={12} lg={6} xl={6} >
                            <Card className="offerTeam-card-data">
                                <Card.Img className="offerTeam-card-img" src={data?.image} />
                                <Card.Title className="offerTeam-card-title">{data?.title}</Card.Title>
                                <Card.Text className="offerTeam-card-description">{data?.description}</Card.Text>
                            </Card>

                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default OfferTeam;