import { Card, Col, Container, Row } from "react-bootstrap";

const Glimpse = ({ data }) => {
    return (

        <>

            <div className="g-main">


                <Container  >
                    <Row  >
                        <Col xs={12} sm={12} md={12} lg={6} xl={6} >
                            <Card className="card1">
                                <Card.Img className="cardimg" src={data?.image} />
                                <Card.Title className="cardtitle">{data?.title}</Card.Title>
                                <Card.Text className="cardtext">{data?.description}</Card.Text>
                            </Card>

                        </Col>
                    </Row>
                </Container>
            </div>







        </>
    )
}

export default Glimpse;