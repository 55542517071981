import React from 'react'

const Explore = ({ data }) => {
    return (
        <React.Fragment>
            <div className='parent-explore'>
                <div>
                    <img src={data?.image} alt='explore' className='img-explore' />
                </div>
                <div className='title-explorer'>
                    {data?.title}
                </div>
                <div className='parent-one-btn'>
                    <button className='btn-explore'>
                        Get Best Quote
                    </button>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Explore
