import { Button, Col, Container, Row } from "react-bootstrap";

const PostTestimation = () => {
    return (
        <>
            <Container>
                <Row>
                    <Col>
                        <div className="post-test-main">
                            <div className="post-test-data">
                                <div className="post-test-data-info">
                                    <p className="post-test-p">In promotion and advertising, a testimonial consists of a person's experience in a written statement extolling the integrity of a product or services.</p>
                                    <Button className="post-test-btn">Post Your Testimonials</Button>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default PostTestimation;