import {
    AppBar,
    Box,
    Container,
    Divider,
    Link,
    List,
    ListItem,
    Stack,
    Typography,
} from "@mui/material";
import { React, useState } from "react";
import { useMediaQuery } from "@mui/material";
import logo from "./../assets/images/logo.png";
import call from "../assets/icons/calling-footer.svg";
import address from "../assets/icons/message-footer.svg";
import { useTheme } from '@mui/material/styles';
import HamburgerMenu from "./HamBurgerMenu";
import styles from '../Css/navbar.css';
import { useNavigate } from "react-router-dom";


const addressData = [
    {
        icon: address,
        title: "MAVDI, RAJKOT, GUJARAT",
    },
    {
        icon: call,
        title: "+91-9510582701",
    },
];

const Header = (props) => {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const [expanded, setExpanded] = useState(false);
    const navigate = useNavigate();

    // const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("md"));
    const [menuMobileVisible, setMenuMobileVisible] = useState(false);
    // ! us used toggle drawer
    // const toggleDrawer =
    //     (open) => (event) => {
    //         if (
    //             event.type === "keydown" &&
    //             ((event).key === "Tab" ||
    //                 (event).key === "Shift")
    //         ) {
    //             return;
    //         }
    //         setMenuMobileVisible(false);
    //     };
    const getNavMenu = () => {
        return (
            <List sx={{
                [theme.breakpoints.up("sm")]: {
                    display: "flex",
                },
                [theme.breakpoints.down("md")]: {
                    flexDirection: "column",
                },
            }}>
                <Link href={'/'} passHref>
                    <ListItem
                        sx={{ color: 'white', textDecoration: "none" }}
                    >
                        Home
                    </ListItem>
                </Link>
                <Box>
                    {/* <div
                        aria-haspopup="false"
                        onClick={() => setExpanded(!expanded)}
                        onMouseOver={() => setExpanded(true)}
                        onMouseMove={() => setExpanded(true)}
                        onMouseLeave={() => setExpanded(false)}
                    >
                        <div style={{ color: 'white', listStyle: 'none' }}>
                            Company
                        </div>

                    </div> */}
                    <ListItem
                        className="company-menu"
                        onClick={() => setExpanded(!expanded)}
                        onMouseOver={() => setExpanded(true)}
                        onMouseMove={() => setExpanded(true)}
                        onMouseLeave={() => setExpanded(false)}
                    >
                        Company
                    </ListItem>
                    {expanded &&
                        <Box
                            className={`serviceMenu ${styles.companyMenu} ${expanded && styles.active}`}
                            onMouseOver={() => setExpanded(true)}
                            onMouseLeave={() => setExpanded(false)}
                        >
                            <div className="parentListItem">
                                <Link href={'/home/0'} className="listItemParent">
                                    <span className={`${styles.textBlack}`}>Red Chilli Powder </span>
                                </Link>
                                <Link className="listItemParent" href="/home/1">
                                    <span className={`${styles.textBlack}`}>Turmeric Powder </span>
                                </Link>
                                <Link className="listItemParent" href="/home/2">
                                    <span className={`${styles.textBlack}`}>Cumin Powder </span>
                                </Link>
                            </div>
                        </Box>
                    }
                </Box>
                <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    style={{
                        backgroundColor: "#FFFFFF",
                    }}
                />
                <ListItem sx={{ cursor: 'pointer', color: 'white' }}
                    onClick={() => navigate("/about")}
                >
                    About
                </ListItem>
                <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    style={{
                        backgroundColor: "#FFFFFF",
                    }}
                />
                <ListItem
                    sx={{ color: 'white', cursor: 'pointer' }}
                    onClick={() => {
                        setMenuMobileVisible(false)
                        navigate("/contact-us")
                    }}
                >
                    Contact
                </ListItem>
            </List >
        );
    };

    const getAppHeader = () => {
        return (
            <Box className="parent-navbar">
                <AppBar position="fixed" sx={{
                    boxShadow: "none",
                    backgroundColor: "#5f7f36",
                    color: "black",
                    wordSpacing: 1,
                }}>
                    {isDesktop && (
                        <Container maxWidth="lg" sx={{ position: 'relative' }}>
                            <Stack
                                direction={{
                                    lg: "row",
                                    md: "row",
                                    sm: "row",
                                    xs: "column",
                                }}
                                p={2}
                                spacing={2}
                                justifyContent="space-between"
                                alignItems={{ lg: "center" }}
                            >
                                <Link href="/">
                                    <img
                                        src={logo}
                                        alt="logo"
                                        style={{
                                            height: isDesktop ? "50px" : "100%",
                                            cursor: "pointer",
                                        }}
                                    />
                                </Link>
                                {getNavMenu()}

                                <Stack
                                    direction={{
                                        // lg: "column",
                                        // md: "column",
                                        // sm: "column",
                                        // xs: "column",
                                    }}
                                    spacing={1}
                                >
                                    {addressData.map((i) => {
                                        return (
                                            <Box sx={{
                                                display: "flex",
                                                width: "270px",
                                            }}>
                                                <img
                                                    src={i.icon}
                                                    alt="adress"
                                                    style={{
                                                        height: isDesktop ? "17px" : "10px",
                                                        cursor: "pointer",
                                                        textAlign: "center",
                                                    }}
                                                />
                                                <Box ml={2}>
                                                    <Typography sx={{
                                                        color: "#FFFFFF",
                                                        fontSize: "16px",
                                                    }}>
                                                        {i.title}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        );
                                    })}
                                </Stack>
                            </Stack>
                        </Container>
                    )}
                    {
                        (!isDesktop) &&
                        <Container maxWidth="lg" sx={{ p: 2, display: 'flex', alignItems: 'center' }}>
                            <Box>
                                <HamburgerMenu />
                            </Box>

                            <Link href="/">
                                <img
                                    src={logo}
                                    alt="logo"
                                    height={'50%'}
                                    width={'65%'}
                                    style={{ display: 'block', margin: '0px auto' }}
                                />
                            </Link>
                        </Container>
                    }
                </AppBar>
            </Box>
        );
    };

    return getAppHeader();
};

export default Header;
