import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { IoIosCall } from 'react-icons/io';
import { PiReadCvLogoBold } from 'react-icons/pi';
import { useNavigate } from "react-router-dom";
import React from "react";

const Products = ({ data }) => {

    const navigate = useNavigate();

    return (
        <>
            <div className="product-main">
                <div className="p-t">
                    <div className="product-title">
                        Latest <span>Products</span>
                    </div>
                </div>
                <div className="product-detail">
                    <Container className="product-card" >

                        {
                            data?.map((item, ind) => {
                                return (
                                    <React.Fragment key={ind}>
                                        <Row className="product-data">
                                            {
                                                (ind % 2 == 0) ?
                                                    <React.Fragment key={ind}>
                                                        <Col xl={6} lg={6} md={12} sm={12} xs={12} className="parent-one">
                                                            <div onClick={() => navigate(`/home/${item?.id}`)}>
                                                                <Image className="product-img" src={item?.image} width={"100%"} height={"100%"} />
                                                            </div>
                                                        </Col>
                                                        <Col xl={6} lg={6} md={12} sm={12} xs={12} className="product-description" >
                                                            <div className="product-info">
                                                                <div className="product-info-title"> {item?.title}</div>
                                                                <div className="product-info-data">
                                                                    <p className="product-info-data-1">Cultivation Type :- {item?.cultivationType}</p>
                                                                    <p className="product-info-data-1">Color :- {item?.color} </p>
                                                                    <p className="product-info-data-1">Form :- {item?.form}</p>
                                                                    <p className="product-info-data-1">Certification :- {item?.certification}</p>
                                                                    <p className="product-info-data-1">Packing Type:- {item?.packingType}</p>
                                                                </div>
                                                                <div className="product-data-btn">
                                                                    <div className="product-data-btn-enquiry">
                                                                        <Button className="enquiry-btn" > <IoIosCall /> Enquiry</Button></div>
                                                                    <div className="product-data-btn-read">
                                                                        <Button className="read-btn" onClick={() => navigate(`/home/${item?.id}`)}><PiReadCvLogoBold /> Read More</Button> </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </React.Fragment> :
                                                    <React.Fragment key={ind}>
                                                        <Col xl={6} lg={6} md={12} sm={12} xs={12} className="product-description" >
                                                            <div className="product-info">
                                                                <div className="product-info-title"> {item?.title}</div>
                                                                <div className="product-info-data">
                                                                    <p className="product-info-data-1">Cultivation Type :- {item?.cultivationType}</p>
                                                                    <p className="product-info-data-1">Color :- {item?.color} </p>
                                                                    <p className="product-info-data-1">Form :- {item?.form}</p>
                                                                    <p className="product-info-data-1">Certification :- {item?.certification}</p>
                                                                    <p className="product-info-data-1">Packing Type:- {item?.packingType}</p>
                                                                </div>
                                                                <div className="product-data-btn">
                                                                    <div className="product-data-btn-enquiry">
                                                                        < Button className="enquiry-btn" ><IoIosCall /> Enquiry</Button></div>
                                                                    <div className="product-data-btn-read">
                                                                        <Button className="read-btn" onClick={() => navigate(`/home/${item?.id}`)}><PiReadCvLogoBold /> Read More</Button> </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col xl={6} lg={6} md={12} sm={12} xs={12} className="parent-one">
                                                            <div onClick={() => navigate(`/home/${item?.id}`)} >
                                                                <Image className="product-img" src={item?.image} width={"100%"} height={"100%"} />
                                                            </div>
                                                        </Col>

                                                    </React.Fragment>
                                            }


                                        </Row>
                                    </React.Fragment>
                                )
                            })


                        }
                    </Container>



                </div>
            </div>

        </>
    )
}


export default Products;