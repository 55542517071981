import {
    Container,
    Divider,
    Grid,
    Stack,
    Typography,
    styled,
    useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import MessageFooter from "../../assets/icons/message-footer.svg";
import CallingFooter from "../../assets/icons/calling-footer.svg";
import PlaceIcon from "@mui/icons-material/Place";

const footerData = [
    {
        title: "Quick Links",
        data: [
            {
                // redirectLink: urls.faqViewPath,
                name: "About Us",
            },
            {
                // redirectLink: urls.faqViewPath,
                name: "Blogs",
            },
            {
                // redirectLink: urls.faqViewPath,
                name: "Products",
            },
            {
                // redirectLink: urls.faqViewPath,
                name: "Event & Achievement",
            },
            {
                // redirectLink: urls.faqViewPath,
                name: "FAQ",
            },
            {
                // redirectLink: urls.faqViewPath,
                name: "Contact Us",
            },
        ],
    },
    {
        title: "Our Services",
        data: [
            {
                // redirectLink: urls.faqViewPath,
                name: "Astrology Stone",
            },
            {
                // redirectLink: urls.faqViewPath,
                name: "Career and Business",
            },
            {
                // redirectLink: urls.faqViewPath,
                name: "Hindu Astrology",
            },
            {
                // redirectLink: urls.faqViewPath,
                name: "Horoscope Astrology",
            },
            {
                // redirectLink: urls.faqViewPath,
                name: "Love and Relationship",
            },
            {
                // redirectLink: urls.faqViewPath,
                name: "Online Astrology",
            },
        ],
    },
];

const AppFooter = () => {
    const theme = useTheme();
    // footer css here to wright
    const BoxWrapper = styled(Box)(() => ({
        backgroundColor: "#5f7f36",
        // height: "70vh",
        display: "flex",
        alignItems: "end",
        justifyContent: "center",
        padding: "30px 0",
        [theme.breakpoints.down("md")]: {
            textAlign: "center",
            // height: "205vh",
        },
        '.footerBox': {
            display: "flex",
            flexDirection: "column",
            [theme.breakpoints.down("md")]: {
                alignItems: "center",
            },
        },
        ".regularFont": {
            fontSize: "15px",
            color: "#FFFFFF",
        },
        ".label1": {
            color: "#FFFFFF",
        },
        ".pointerCursor": {
            cursor: "pointer",
        },
        ".copyright": {
            textAlign: "center",
        },
    }))


    const getYear = () => {
        return new Date().getFullYear();
    };

    return (
        <BoxWrapper
        >
            <Container maxWidth="lg">
                <Grid
                    container
                    spacing={2}
                    sx={{
                        display: "flex",
                    }}
                >
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Stack spacing={2}
                            className="footerBox"
                        >
                            <Stack direction={"row"} gap={1} sx={{ svg: { height: "19px" } }}>
                                <PlaceIcon />
                                <Typography
                                    className="regularFont"
                                >
                                    Flat No. 102 1st Floor Arham Flats 40 Ft Road Mavdi,
                                    <br />
                                    Mavdi, Rajkot, Gujarat - 360004, India
                                </Typography>
                                <Typography
                                    className="regularFont"
                                ></Typography>
                            </Stack>
                            <Stack direction={"row"} gap={1}>
                                <img src={CallingFooter} alt="CallingFooter" />
                                <Typography
                                    className="regularFont"
                                >
                                    +91-9510582701, +91-7600781161
                                </Typography>
                            </Stack>
                            <Stack direction={"row"} gap={1}>
                                <img src={MessageFooter} alt="MessageFooter" />
                                <Typography
                                    className="regularFont"
                                >
                                    denish@agribucketinternational.com
                                </Typography>
                            </Stack>
                        </Stack>
                    </Grid>
                    {footerData.map((data, index) => {
                        return (
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Box
                                    className="footerBox"
                                >
                                    <Stack spacing={1}>
                                        <Typography
                                            className="label1"
                                        >{data.title}</Typography>
                                        {data.data.map((i) => {
                                            return (
                                                <Typography
                                                    className="regularFont pointerCursor"
                                                // sx={[classes.regularFont, classes.pointerCursor]}
                                                >
                                                    {i.name}
                                                </Typography>
                                            );
                                        })}
                                    </Stack>
                                </Box>
                            </Grid>
                        );
                    })}
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        lg={4}
                        xl={4}
                    // sx={{ ...centerItemFlex }}
                    ></Grid>
                </Grid>
                <Divider sx={{ margin: "30px 0" }} />
                <Box
                    className="copyright"
                >
                    <Typography
                        className="regularFont"
                    >
                        {`© ${getYear()}. All Rights Reserved`} Developed & Managed By <a style={{ color: 'skyblue' }} href="https://indiibot.com"> Indiibot </a>Pvt. Ltd.
                        replace all this indiibot
                    </Typography>
                </Box>
            </Container>
        </BoxWrapper>
    );
};

export default AppFooter;
