import React from "react";
import { ErrorMessage, Field, Formik } from "formik";
import { Button, Col, Form, Row } from "react-bootstrap";
import * as Yup from "yup";
import { toast } from 'react-hot-toast';
import { send } from 'emailjs-com';

const EnquiryForm = () => {

    const handleSchema = Yup.object().shape({
        username: Yup.string().required("username is required"),
        email: Yup.string().required("email is required"),
        Phone: Yup.number().required("Phone is required"),
        country: Yup.string().required("country is required"),
        text: Yup.string().required("text is required")
    })
    return (
        <>
            <div className="enquiry-main">
                <div className="enquiry-title">
                    Quick <span>Enquiry</span></div>
                <div className="enquiry-data">
                    <Formik
                        initialValues={{ username: "", email: "", country: "", Phone: "", text: "" }}
                        validationSchema={handleSchema}
                        // onHide={(handleClose)=()=>{setShow(false)}}
                        // onClick={(handleShow)=()=>{setShow(true)}}
                        onSubmit={(value) => {
                            send("service_gwbvwjo", "template_pbgwkjo", value, "C3Mg2tnmRpb3HzdMe")
                                .then((response) => {
                                    console.log("Success", response.status, response.text);
                                    toast.success('Email sent successfully !!')
                                })
                                .catch((err) => {
                                    console.log("Failed", err);
                                });
                        }}  >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            // handleClose,
                            // handleShow

                        }) => (<>

                            <Form onSubmit={handleSubmit} className="enquiry-form">
                                <Row className="mb-3 ">
                                    <Form.Group as={Col} className="mb-3" >
                                        <Form.Control className="form"
                                            type="text"
                                            name="username"
                                            placeholder="Your Name"
                                            value={values.username}
                                            onChange={handleChange} />
                                        <ErrorMessage name="username"
                                            render={(msg) => {
                                                return (<div style={{ color: 'red', fontWeight: 700 }}>
                                                    {msg}
                                                </div>)
                                            }}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} className="mb-3" >
                                        <Form.Control className="form"
                                            type="email"
                                            name="email"
                                            placeholder="Email"
                                            value={values.email}
                                            onChange={handleChange} />
                                        <ErrorMessage name="email"
                                            render={(msg) => {
                                                return (<div style={{ color: 'red', fontWeight: 700 }}>
                                                    {msg}
                                                </div>)
                                            }} />
                                    </Form.Group>

                                </Row>


                                <Row className="mb-3 ">
                                    <Form.Group as={Col} controlId="formGridState">
                                        {/* <Form.Select name="country" defaultValue="Choose..." className="form"> */}
                                        <Field className="form" as="select" name="country">
                                            <option>Choose...</option>
                                            <option>india </option>
                                            <option>UK</option>
                                            <option>US</option>
                                        </Field>
                                        {/* </Form.Select> */}
                                        <ErrorMessage name="country"
                                            render={(msg) => {
                                                return (<div style={{ color: 'red', fontWeight: 700 }}>
                                                    {msg}
                                                </div>)
                                            }} />
                                    </Form.Group>



                                    <Form.Group as={Col}>
                                        <Form.Control className="form"
                                            type="number"
                                            name="Phone"
                                            placeholder="Phone/Mobile"
                                            value={values.Phone}
                                            onChange={handleChange}
                                        >


                                        </Form.Control>
                                        <ErrorMessage name="Phone"
                                            render={(msg) => {
                                                return (<div style={{ color: 'red', fontWeight: 700 }}>
                                                    {msg}
                                                </div>)
                                            }} />
                                    </Form.Group>
                                </Row>


                                <Form.Group className="form"  >

                                    <Form.Control className="form"
                                        rows="4"
                                        // type="textarea"
                                        as="textarea"
                                        name="text"
                                        placeholder="Leave a message for u "
                                        onChange={handleChange}

                                    />
                                    <ErrorMessage name="text"
                                        render={(msg) => {
                                            return (<div style={{ color: 'red', fontWeight: 700 }}>
                                                {msg}
                                            </div>)
                                        }} />
                                </Form.Group>




                                <Button type="submit" className="enquiry--btn1" >Send Message</Button>

                            </Form>


                        </>
                        )}


                    </Formik>

                </div>



            </div>

        </>
    )
}

export default EnquiryForm;