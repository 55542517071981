import { Suspense, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import HomePage from './components/Home/HomePage';
import Header from './components/Header';
import ProductDetails from './components/Home/ProductsDetails';
import AboutPage from './components/About';
import ContactUsPage from './components/ContactUs';


function App() {

  return (
    <div className="App">
      <Router>
        <Header />
        <Suspense fallback={<> LODDING.... </>}>
          <Routes>
            <Route exact path="/" element={<HomePage />} />
            <Route exact path="/home/:productId" element={<ProductDetails />} />
            <Route exact path="/about" element={<AboutPage />} />
            <Route exact path="/contact-us" element={<ContactUsPage />} />
          </Routes>
        </Suspense>
      </Router>

    </div>
  );
}

export default App;
