import { Button, Col, Container, Form, Row } from "react-bootstrap";

const SearchEmail = () => {
    return (
        <div className="searchemail-main">
            <div className="searchemail-title">News Letter</div>
            <div className="d-flex align-items-center justify-content-center text-center searchemail-form  ">

          
           <Form inline>
                <Row>
                    <Col xs="auto">
                        <Form.Control  
                            type="emai"
                            placeholder="Enter email"
                            className=" mr-sm-2 searchemail"
                            name="email"
                        />
                    </Col>
                    <Col xs="auto">
                        <Button className="searchemail-btn" type="submit">Submit</Button>
                    </Col>
                </Row>
            </Form>
           
            </div>

        </div>
    )
}

export default SearchEmail;