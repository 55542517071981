import { Grid } from "@mui/material";
import { Button, Container, Image, Stack } from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from "react-router-dom";

const Contain = () => {
    const navigate = useNavigate();
    return (
        <div className="main-parent">
            <div className="contain">
                <Container className="con1">
                    <Row>
                        <Col xl={6} lg={6} md={12} sm={12} xs={12} >
                            <div>
                                <h1 className="contain-title">Welcome to our website </h1>
                                <div className="contain-data">Agri Bucket International Agri Bucket International is a renowned manufacturer and supplier of the best quality Indian species. We have expertise in Red chilli Powder, Turmeric powder, and Cumin Powder. We are working from Rajkot, Gujarat, India. Our spices are handpicked, fresh-ground, and have an exceptional flavour. As a leading Indian spice exporter, manufacturer, and supplier, Agri Bucket International has been in business since 2022 to create authentic spices that result in a happy cooking experience. We have a completely integrated business-to-business process. We specialise in combining decades of practise with advanced manufacturing facilities. Packaging Our prime goal as a company is to have customer service that is not simply good but outstanding. We understand that .</div>
                                <Button className="contain-btn" onClick={() => navigate('/about')}> Read more </Button>
                            </div>
                        </Col>
                        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                            <div className="parent-image">
                                <Image src="https://catalog.wlimg.com/about-image/about-04.jpg" width={'80%'} height={'100%'} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Contain;


