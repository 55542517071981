import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';


export default function HamburgerMenu() {
    const [showProduct, setShowProduct] = React.useState(false)
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Box
            sx={{ width: 250 }}
            role="presentation"
            // onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <Box>
                {
                    showProduct ?
                        <Box>
                            <List sx={{ p: 0 }}>
                                <ListItem disablePadding sx={{ py: 0.5 }} onClick={() => setShowProduct(false)}>
                                    <ListItemButton sx={{ borderBottom: '1px solid #00000012', background: '#F0F0F0', pb: 2 }}>
                                        <ChevronLeftIcon /> Products
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding sx={{ py: 0.5 }}>
                                    <ListItemButton href='/home/0' sx={{ borderBottom: '1px solid #00000012' }}>
                                        <ListItemText primary="Red Chilli Powder" />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding sx={{ py: 0.5 }}>
                                    <ListItemButton href='/home/1' sx={{ borderBottom: '1px solid #00000012' }}>
                                        <ListItemText primary="Turmeric Powder" />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding sx={{ py: 0.5, borderBottom: '1px solid #00000012' }}>
                                    <ListItemButton href='/home/2'>
                                        <ListItemText primary="Cumin Powder" />
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        </Box> :
                        <List sx={{ p: 0 }}>
                            <ListItem disablePadding sx={{ background: '#5F7F36', color: 'white', fontWeight: 800, py: 0.5 }}>
                                <ListItemButton>
                                    <ListItemText primary="Home" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding sx={{ py: 0.2, borderBottom: '1px solid #00000012' }}>
                                <ListItemButton component="a" href="/about">
                                    <ListItemText primary="About Us" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding sx={{ px: 2, py: 1, borderBottom: '1px solid #00000012' }} onClick={() => setShowProduct(true)}>
                                <ListItemText primary="products" />
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton component="a" href="/simple" sx={{ py: 1.2, borderBottom: '1px solid #00000012' }}>
                                    <ListItemText primary="Contact Us" />
                                </ListItemButton>
                            </ListItem>
                        </List>
                }

            </Box>
        </Box >
    );

    return (
        <div>
            {['left'].map((anchor) => (
                <React.Fragment key={anchor}>
                    <Button onClick={toggleDrawer(anchor, true)}>
                        <MenuIcon sx={{ color: 'white', fontSize: '35px' }} /> </Button>
                    <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                    >
                        {list(anchor)}
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    );
}