import { Box, Grid, Typography, styled } from '@mui/material';
import React from 'react';
import './../Css/about.css';
import { MdChevronRight } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import AppFooter from './AppFooter/Footer';

const AboutPage = () => {
    const BoxWrapper = styled(Box)(() => ({
        marginTop: '5.2rem',
        padding: "0px 20%",
        width: '100%',
        ".product-info": {
            fontSize: '12px',
            fontWight: 500,
            padding: '8px'
        },
        ".product-info-header": {
            color: "#5f7f36",
            fontSize: '14px',
            padding: '6px',
            borderBottom: "1px dotted #5f7f36"
        },
        ".description-address": {
            display: 'flex',
            fontSize: "12px",
            padding: '6px',
            borderBottom: "1px dotted #5f7f36"
        },
        '.about-header': {
            fontSize: '24px',
            fontWight: 800,
            padding: '10px'
        },
        '.parent-navigate': {
            backgroundColor: '#f2f2f2',
            fontSize: '13px',
            padding: '12px'
        },
        '.table-parent': {
            padding: "20px 0px"
        }
    }))
    const navigate = useNavigate();
    const aboutTableData = [
        { header: 'Nature of Business', description: "Supplier & Trader", id: 1 },
        { header: 'Number of Employees', description: '	10 Number of People', id: 2 },
        { header: 'Year of Establishment', description: '2022', id: 3 },
        { header: 'Market Covered', description: 'Pan India', id: 4 },
        { header: 'Name of CEO', description: 'Mr. Meet Patel', id: 5 },
        { header: 'Legal Status of Firm', description: 'Individual (Sole proprietorship)', id: 6 },
        { header: 'Gst No.', description: '24CNYPA0897C1ZV', id: 7 }
    ]
    return (
        <React.Fragment>
            <BoxWrapper>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                        <Box>
                            <Typography className='about-header'>
                                About Us
                            </Typography>
                            <Box className="parent-navigate">
                                <span onClick={() => navigate("/")} style={{ color: 'gray', cursor: 'ponter' }}>Home</span> <MdChevronRight /> About Us
                            </Box>
                            <Typography className='text-description'>
                                Agri Bucket International is a renowned manufacturer and supplier of the best quality Indian species. We have expertise in Red chilli Powder, Turmeric powder, and Cumin Powder. We are working from Rajkot, Gujarat, India.
                            </Typography>
                            <Typography className='text-description'>
                                Our spices are handpicked, fresh-ground, and have an exceptional flavour. As a leading Indian spice exporter, manufacturer, and supplier, Agri Bucket International has been in business since 2022 to create authentic spices that result in a happy cooking experience. We have a completely integrated business-to-business process. We specialise in combining decades of practise with advanced manufacturing facilities.
                            </Typography>
                            <Typography className='text-description'>
                                <div>
                                    Packaging
                                </div>
                                Our prime goal as a company is to have customer service that is not simply good but outstanding. We understand that the customer is king and aim to convey complete satisfaction.
                            </Typography><Typography className='text-description'>
                                We have a group of professional and industry experts who will deliver end-to-end solutions to all packaging problems. We confirm that there is complete precision in the delivery process.
                            </Typography>
                            <Typography className='text-description'>
                                Our customers also have the flexibility to personalise the material, colour, size, and design. Our packaging options presently range from glass jars, HDPE bags, paper bags, and aluminium pouches
                            </Typography>
                            <Typography className='text-description'>
                                Agri Bucket International also accepts bulk orders from customers. We also provide customised packaging solutions. We work through a fully integrated business-to-business operation and are specialised in combining decades of experience with advanced manufacturing facilities.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box sx={{ p: 2 }}>
                            <Typography className="product-title-about">
                                Products
                            </Typography>
                            <Typography className='product-info'>
                                Red Chilli Powder
                            </Typography>
                            <Typography className='product-info'>
                                Turmeric Powder
                            </Typography>
                            <Typography className='product-info'>
                                Cumin Power
                            </Typography>
                        </Box>
                        <Box sx={{ p: 2 }}>
                            <Typography className="product-title-about">
                                Contact Us
                            </Typography>
                            <Typography className='product-info-header'>
                                AGRI BUCKET INTERNATIONAL
                            </Typography>
                            <Typography className='product-info'>
                                Turmeric Powder
                            </Typography>
                            <Typography className='product-info'>
                                Cumin Power
                            </Typography>
                            <Typography className='description-address'>
                                Flat No. 102 1st Floor Arham Flats 40 Ft Road Mavdi, Mavdi, Rajkot, Gujarat - 360004, India
                            </Typography>
                            <Typography className='description-address'>
                                Mobile : +91-9510582701, +91-7600781161
                            </Typography>
                            <Typography className='description-address'>
                                E-mail : denish@agribucketinternational.com
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Box className='table-parent'>
                    <table border="1|0" style={{ fontSize: '14px', width: '65%' }} >
                        {aboutTableData?.length > 0 ? aboutTableData?.map((data, index) => {
                            return (
                                <tr key={index}>
                                    <td className='td-header'>{data?.header}</td>
                                    <td> {data?.description}</td>
                                </tr>
                            )
                        }) : []}
                    </table>

                </Box>

            </BoxWrapper>
            <AppFooter />
        </React.Fragment>
    )
}

export default AboutPage;
