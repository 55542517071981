import React from 'react'
import Header from '../Header'
import AppFooter from '../AppFooter/Footer'
import { styled, Box } from '@mui/material'
import ImgCarousel from './ImgCarousel'
import Contain from './Contain';
import img1 from '../../assets/images/Glimpse/g1.jpg'
import img2 from '../../assets/images/Glimpse/g2.jpg'
import img3 from '../../assets/images/Glimpse/g3.jpg'
import img4 from '../../assets/images/Glimpse/g4.jpg'
import chilli from '../../assets/images/Products/chilli.png';
import turmric from '../../assets/images/Products/turmeric.png';
import cumin from '../../assets/images/Products/cumin.png';
import offer from '../../assets/images/OfferTeam/offer.jpg';
import team from '../../assets/images/OfferTeam/team.jpg';
import pack from '../../assets/images/OfferTeam/pack.jpg'
import Glimpse from './Glimpse'
import Products from './Products'
import OfferTeam from './OfferTeam'
import PostTestimation from './PostTestimation'
import EnquiryForm from './EnquiryForm'
import SearchEmail from './SearchEmail'
import ProductDetails from './ProductsDetails'
import importImg from './../../assets/images/importMap.png';


const BoxWrapper = styled(Box)(() => ({
    marginTop: '5.2rem',
    width: '100%',
    // height: "calc(100vh - 12vh)"
}))

const GlimpseData = [
    {
        id: 0,
        image: img1,
        title: "Nature of Business",
        description: "Supplier & Trader",
    },
    {
        id: 1,
        image: img2,
        title: 'Number of Employees',
        description: '10 Number of People',
    },
    {
        id: 2,
        image: img3,
        title: 'Year of Establishment',
        description: '2022'
    },
    {
        id: 3,
        image: img4,
        title: 'Market Covered',
        description: "Pan India",
    }
]

const ProductData = [
    {
        id: 0,
        image: chilli,
        title: "Red Chilli Powder",
        cultivationType: "Organic",
        color: "Red",
        form: "Powder",
        certification: "FSSAI Certified",
        packingType: "Plastic Packet",
    },
    {
        id: 1,
        image: turmric,
        title: "Turmeric Powder",
        cultivationType: "Organic",
        color: "Yellow",
        form: "Powder",
        certification: "FSSAI Certified",
        packingType: "Plastic Packet",
    },
    {
        id: 2,
        image: cumin,
        title: "Cumin Powder",
        cultivationType: "Organic",
        color: "Brown",
        form: "Powder",
        certification: "FSSAI Certified",
        packingType: "Plastic Packet",
    },
]

const OfferTeamData = [
    {
        id: 0,
        image: offer,
        title: "What We Offers?",
        description: "Agri Bucket International is a renowned manufacturer and supplier of the best quality Indian species. We have expertise in Red chilli Powder, Turmeric powder, and Cumin Powder. We are working from Rajkot, Gujarat, India.",
    },
    {
        id: 1,
        image: team,
        title: "Our Team",
        description: "We have a group of professional and industry experts who will deliver end-to-end solutions to all packaging problems. We confirm that there is complete precision in the delivery process.",
    },
    {
        id: 2,
        image: pack,
        title: "Packaging",
        description: "Our prime goal as a company is to have customer service that is not simply good but outstanding. We understand that the customer is king and aim to convey complete satisfaction.",
    },

]
const HomePage = () => {

    return (
        <BoxWrapper>
            <div>
                <ImgCarousel />
                <Contain />

                <div className='glimpse'>
                    <div className="titile">
                        Glimpse <span>of Our Company</span>
                    </div>
                    <div className='main'>
                        {
                            GlimpseData?.map((data, ind) => {
                                return (
                                    <Glimpse data={data} />
                                )
                            })
                        }
                    </div>
                </div>
                <Box>
                    {/* //! image section */}
                    <img src={importImg} className='import-map' alt='import-image' />
                </Box>
                <Products data={ProductData} />
            </div>

            <div>
                <div className='offerTeam-card-main'>
                    {
                        OfferTeamData?.map((data, ind) => {
                            return (
                                <OfferTeam data={data} />
                            )
                        })
                    }
                </div>
            </div>

            <div class="parallax-movement">
                <PostTestimation />
            </div>

            <EnquiryForm />
            <SearchEmail />


            <AppFooter />
        </BoxWrapper>
    )
}

export default HomePage
